.wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px 0;
}

.phones {
    color: var(--dark-blue);
    font-family: Bebas Neue, sans-serif;
    font-weight: bold;
    font-size: 20rem;
}

.phone {
    display: block;
    font-family: inherit;
    margin: 12px 0;
    text-align: center;
}

@media screen and (min-width: 1025px) {
    .wrapper {
        flex-direction: row;
        margin: 16px 0;
        padding: 0 20px;
    }

    .phone {
        display: inline-block;
    }

    .phones {
        text-align: center;
    }
}
.links {
    align-items: center;
    display: flex;
    font-size: 15rem;
    gap: 32px;
    justify-content: center;
    padding-block: 32px;
}

.links span[role="button"] {
    border-bottom: 1px solid transparent;
    padding: 3px 2px;
}

.links span.active {
    border-color: var(--teal);
    color: var(--teal);
}

.shown {
    display: block;
}

.hidden {
    display: none;
}
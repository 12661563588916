.spec,
.link {
    color: var(--teal);
}

.spec {
    margin-left: -16px;
    margin-top: 26px;
}

.link {
    display: block;
    margin-left: -7px;
    margin-top: 36px;
}
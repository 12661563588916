.input {
    background: var(--white) !important;
    border: 1px solid var(--box-shadows);
    border-radius: 8px;
    box-shadow: none;
    height: 44px;
    margin-bottom: 14px;
    padding: 1px 14px;
    width: 100%;
}

.clear_action {
    display: none;
}

.disabled {
    opacity: .4;
    pointer-events: none;
}

.label_text {
    color: var(--black);
    display: block;
    margin-bottom: 5px;
}
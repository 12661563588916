.wrapper {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
}

.wrapper {
    justify-content: center;
}

.wrapper > button {
    background: var(--white);
    border: 1px solid transparent;
    border-radius: 6px;
    box-shadow: 0 0 3px var(--box-shadows);
    color: #777;
    cursor: pointer;
    font-size: 13px;
    margin-bottom: 10px;
    padding: 10px;
}

.wrapper > button[data-active],
.wrapper > button:hover {
    background: var(--teal);
    border-color: var(--teal);
    color: var(--white);
}

.wrapper > button:not(:last-child) {
    margin-right: 10px;
}

@media screen and (min-width: 1101px) {
    .wrapper:not(.center) {
        justify-content: flex-start;
    }
}
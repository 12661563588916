.select {
    background: transparent;
    border: none;
    border-radius: 0 10px 10px 0;
    cursor: pointer;
    padding: 6px;
}

.label {
    color: var(--black);
}

.label:not(:empty)::after {
    content: ':';
}
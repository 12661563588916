.city {
    color: var(--teal);
    font-family: Bebas Neue, sans-serif;
    font-size: 27rem;
    font-weight: 600;
    text-align: center;
}

@media screen and (min-width: 801px) {
    .city {
        font-size: 25rem;
        font-weight: 400;
    }
}
.empty {
    align-items: center;
    display: flex;
    flex-direction: column;
    grid-column: 1 / -1;
    margin-block: 46px;
}

.empty img {
    border-radius: 20px;
}
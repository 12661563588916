.outerwrapper {
    background-color: var(--gray);
    padding-top: 66%;
    position: relative;
    width: 100%;
}

.wrapper {
    inset: 0;
    position: absolute;
}
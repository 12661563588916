.err,
.err2 {
    animation: pulse .5s ease-in-out forwards;
    color: var(--red);
    font-size: 12rem;
    margin: 2px 0 10px 4px;
    transform-origin: left center;
}

.err::after {
    content: '!';
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.4);
    }

    100% {
        transform: scale(1);
    }
}
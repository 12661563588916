@media screen and (min-width: 700px) {
    .container {
        display: grid;
        gap: 40px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media screen and (min-width: 1150px) {
    .container {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

@media screen and (min-width: 1450px) {
    .container {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}
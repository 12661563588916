.container {
    gap: 40px;
}

.container,
.toggle-form {
    display: flex;
    flex-direction: column;
}

.form-pocket {
    --pad: var(--n-container-hz-pad);

    background: var(--white);

    margin-inline: calc(-1 * var(--pad));
    padding: var(--pad);
}

.content-pocket {
    flex-grow: 1;
    flex-shrink: 1;
}

.toggle-form {
    flex-wrap: wrap;
}

.toggle-form hr {
    --pad: var(--n-container-hz-pad);

    margin-inline: calc(-1 * var(--pad));
    margin-top: -5px;
    width: calc(100% + 2* var(--pad));
}

.toggle-form-actions {
    align-items: center;
    display: flex;
    gap: 6px;
    height: 46px;
    justify-content: space-between;
}

.toggled-form {
    display: none;
}

.toggled-form.show {
    display: block;
}

.sticky-bottom {
    background: var(--white);
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: center;
    padding-block: 16px;
    position: sticky;
    /* 22px --span SearchForm.module.css */
    width: calc(100% + 22px);
    z-index: 5;
}

.sticky-bottom button {
    margin: 0 !important;
}

.sticky-top {
    position: sticky;
    top: 0;
    z-index: 50;
}

@media screen and (min-width: 1051px) {
    .container {
        flex-direction: row;
    }

    .form-pocket {
        --pad: 20px;
        --width: 300px;

        align-self: flex-start;

        flex-grow: 0;
        flex-shrink: 0;

        margin: 0;
        max-width: 300px;
        min-width: 300px;
    }
}
.gray {
    background-color: var(--background-grey);
}

.white {
    background-color: white;
}

.borders {
    border-color: var(--input-border-c);
    border-style: solid;
    border-width: 1px 0;
}
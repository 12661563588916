.wrapper {
    background-color: white;
    border-bottom: 1px solid var(--input-border-c);
    border-top: 1px solid var(--input-border-c);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-around;
    margin: 0px -20px;
    max-width: calc(100% + 20px);
    padding-bottom: 20px;
}

.name {
    font-size: 18rem;
    font-weight: bold;
    margin-bottom: 18px;
    padding: 0 10px;
}

.name:first-child {
    margin-top: 20px;
}

.link {
    color: var(--teal);
    font-size: 15.6rem;
    margin: 6px 0;
    text-decoration: underline;
}

.name,
.link {
    display: block;
    text-align: center;
}

@media screen and (min-width: 1025px) {
    .wrapper {
        border-bottom: none;
        border-top: none;
        margin: 0;
        padding-bottom: 0;
    }
}
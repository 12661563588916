.triptic {
    margin: 0 -20px;
}

.inner {
    display: flex;
    flex-direction: column;
    grid-gap: 1px;
}

@media screen and (min-width: 1025px) {
    .triptic {
        background-color: var(--input-border-c);
        border-color: var(--input-border-c);
        border-style: solid;
        border-width: 0 1px;
        display: grid;
        grid-gap: 1px;
        grid-template-columns: 3fr 1fr 1fr;
        margin: 0;
    }

    .inner {
        border-bottom: 1px solid var(--input-border-c);
    }
}
.placeholder {
    align-items: center;
    color: inherit;
    cursor: pointer;
    display: flex;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    gap: 6px;
    margin: 6px;
    padding: inherit;
}

.placeholder span {
    transform: scale(.85);
}